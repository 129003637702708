.image__container {
	display: flex;
	flex-direction: column-reverse;
	gap: 4rem;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100vh;

	background-color: #efefef;
	.logo__main {
		width: 50%;
	}
}
